import React, { FC } from 'react';
import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';
import { renderReact } from '../../fragmentHelper/renderReactCustom';

import { PagePropsType } from '@next-common/redux-apollo-helper';
import { Didomi as DidomiContainer } from '@next-common/didomi';

export const serverCanCache = true;

interface DidomiProps {
  noticeId?: string;
}

const Didomi: FC<DidomiProps & PagePropsType> = ({ initialApolloState, initialReduxState, moduleName, noticeId }) => {
  return (
    <ReduxApolloFragmentWrapperChildren
      initialApolloState={initialApolloState}
      initialReduxState={initialReduxState}
      moduleName={moduleName}
      reducers={{}}
    >
      <DidomiContainer didomiNoticeId={noticeId} />
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const renderFunction = renderReact('Didomi', Didomi);
export const serverCanCashe = true;
